<template>
<div>

  <section id="" class="hero is-small ">
      <div class="hero-title">
        <div class="container">


              <div class="columns  is-centered is-vcentered" >
                <div class="column  is-centered"> 
                  <img src="../assets/img/about/Ann-LogoContact.jpg"  >
                </div>
                <div class="column is-4 is-centered">
                
                  <div class="tile  is-vertical is-parent is-centered ">
                    <div class="tile is-child ">
                      <img src="../assets/img/about/Contact-Us_Ann-154px.jpg"  >
                      
                    </div>
    
                    <div class="tile is-child is-vertical">

                      <div class="block">
                          <h6 class="subtitle has-text-center">
                            Ann Hills is a registered Wedding Celebrant based in the Noosa area.
                          </h6>
                      </div>
                      <div class="block">
                          <h6 class="subtitle has-text-center">
                            Ann conducts all types of ceremonies in and around the wider Sunshine Coast area and hinterland. 
                          </h6>
                      </div>

                    </div>

                    <div class="tile is-child ">
                      <button class="button " style="color:#B38F06;" onclick="location.href='mailto:info@annhillscelebrant.com.au';">Contact Ann</button>
                    </div>

                </div>

                </div>
              </div>


 
        </div>
      </div>
  </section>

 <section class="hero">
        <figure class="image">
           <img  src="../assets/img/carousel-landscape/2.jpg">
        </figure>

 </section>


 <section id="" class="hero is-small ">

    <div class="hero-body">
 
         <div class="container ">
            <h2 class="title">Weddings</h2>


            <div class="columns  is-vcentered">
                <div class="column  is-vcentered">
                <img src="../assets/img/weddings/img2.png">
              </div>
              <div class="column is-vcentered">  

                <div class="block">
                    <h6 class="title2 has-text-left">
                      Ann Hills will guide you, step by step, through the official side of your wedding with ease, allowing you to make it to the alter, relaxed and ready to enjoy your big day.
                    </h6>
                </div>
                <div class="block">
                    <h6 class="title2 has-text-left">
                      Exchanging your vows is the moment when legally you are joined together and it needs to be handled professionally and with poise and elegance, and Ann is just the person for the task. 
                    </h6>
                </div>
                



              </div>
            
            </div>
        
          </div>

    </div>
    
  </section>

  <section id="bg-2" class="hero is-medium ">
   
    <div class="hero-body">
      <div class="container">
        <div class="box">
        
         <div class="columns ">
             <div class="column">

              <div class="block">
                  <h6 class="subtitle has-text-left">
                    Ann truly understands that when planning your Sunshine Coast wedding, each couple's personal circumstances and needs vary enormously. 
                  </h6>
              </div>
              <div class="block">
                  <h6 class="subtitle has-text-left">
                     Ann is happy to accommodate your individual needs and work in collaboration with you to plan and design each stage of the ceremony, allowing for you to be confident and relaxed, and enjoy your special day.
                  </h6>
              </div>

          </div>
          <div class="column"> 
            <img src="../assets/img/carousel-landscape/3.jpg">
          </div>
        
         </div>

        </div>
      </div>
    </div>
  </section>


 <section id="" class="hero is-small ">
     
    <div class="hero-body">
      <div class="container">
        <div class="columns ">
          <div class="column">  
       
              <img src="../assets/img/weddings/images/17.jpg">
          </div>
          <div class="column">
            <img src="../assets/img/weddings/images/16.jpg">
          </div>
          <div class="column">
              <img src="../assets/img/weddings/images/3.jpg">
          </div>
        
        </div>
      </div>
    </div>
  </section>


  <section id="bg-4" class="hero is-medium ">
   
    <div class="hero-body">
      <div class="container">
        <div class="box">
        
         <div class="columns ">
          <div class="column"> 
            <img src="../assets/img/weddings/images/4.jpg">
          </div>
          <div class="column">

              <div class="block">
                  <h6 class="subtitle has-text-left">
                   Queensland's Sunshine Coast has some simply stunning natural locations for weddings and many of our local landmarks, have become world famous wedding locations, such as Noosa Main Beach.
                  </h6>
              </div>
              <div class="block">
                  <h6 class="subtitle has-text-left">
                      Whether you are a local couple planning a beach or hinterland wedding or you are from interstate or overseas, Ann is able to assist you to host a wedding here. 
                  </h6>
              </div>

          </div>
         </div>

        </div>
      </div>
    </div>
  </section>


 <section id="" class="hero is-small ">

    <div class="hero-body">
 
         <div class="container has-text-centered">
            <h2 class="title">Baby Namings</h2>


            <div class="columns ">
               <div class="column">
                <img src="../assets/img/banners/sunshine_coast_naming.jpg">
              </div>
              <div class="column">  
                <h6 class="title2">
                    Baby naming is a civil ceremony, similar to that of a Christening, without any religious influences.  It is to acknowledge the birth of a child, and formally introduce your child to your closest family and friends.                  
                 </h6>
              </div>
             
            </div>
        
          </div>

    </div>
    
  </section>


  <section id="bg-3" class="hero is-medium ">
    <div class="hero-body">
      <div class="container">
        <div class="box">
        
         <div class="columns ">
          <div class="column"> 
             <img src="../assets/img/babynamings/adorable-baby-cute-69096.jpg">
          </div>
          <div class="column">
                   
            <div class="block">
                  <h6 class="subtitle has-text-left">
                   You can invite various members of your family and friends, to participate in the Baby Naming ceremony, offering readings or poems, or even creating a special memento presented to the baby.
                  </h6>
              </div>
              <div class="block">
                  <h6 class="subtitle has-text-left">
                      It is also an opportunity to officially appoint Godparents or guardians.
                  </h6>
              </div>
              <div class="block"> 
                  <h6 class="subtitle has-text-left">
                      Baby or child naming ceremonies can be a casual or formal affair, depending on your preference, Ann is happy to accommodate your wishes for this special occasion.
                  </h6>
              </div>
 
                
            

          </div>
        
         </div>

        </div>
      </div>
    </div>
  </section>

 <section id="" class="hero is-small ">

    <div class="hero-body">
 
         <div class="container has-text-centered">
            <h2 class="title">Funerals</h2>

            <div class="columns ">
              <div class="column">
                <img src="../assets/img/banners/sunshine_coast_funeral-memorial.jpg">
              </div>
              <div class="column">  
                <h6 class="title2  has-text-left">
                    Ann Hills, will professionally and compassionately, walk you and your family through each step of the funeral service, and help make it the memorable celebration of life that it should be.
                </h6>
              </div>
           
            </div>
        
          </div>

    </div>
    
  </section>

 <section id="bg-1" class="hero is-medium ">
    <div class="hero-body">
      <div class="container">
        <div class="box">
        
         <div class="columns ">
          <div class="column"> 
            <img src="../assets/img/funerals/dawn-dusk-hd-wallpaper-36717.jpg">  
          </div>
          <div class="column">
            <div class="block">
                  <h6 class="subtitle has-text-left">
                   When a loved one passes, grief, sadness and the inevitable sense of loss can be overwhelming.
                  </h6>
              </div>
              <div class="block">
                  <h6 class="subtitle has-text-left">
                      We are all different, and it is important that the funeral service be as individual as you are and that the deceased’s life is celebrated appropriately to reflect their personality, and their life.
                  </h6>
              </div>
              <div class="block"> 
                  <h6 class="subtitle has-text-left">
                        Ann will consult with you, to write a fitting ceremony, which will provide your family comfort and peace.
                  </h6>
              </div>
          </div>
         </div>

        </div>
      </div>
    </div>
  </section>
   

    <footer class="footer ">

 
        <div class="columns  is-centered is-vcentered" >
          <div class="column  is-centered"> 
             <img src="../assets/img/about/Ann-LogoContact.jpg"  >
          </div>
          <div class="column is-4 is-centered">
          
            <div class="tile  is-vertical is-parent is-centered ">
              <div class="tile is-child ">
                <img src="../assets/img/about/Contact-Us_Ann-154px.jpg"  >
                
              </div>
              <div class="tile is-child ">
                <button class="button " style="color:#B38F06;" onclick="location.href='mailto:info@annhillscelebrant.com.au';">Contact Ann</button>
              </div>
              <div class="tile is-child ">
                <img src="../assets/img/about/Home_AMC-Member_tag.jpg"  >
              </div>
           </div>

          </div>
        </div>
     

    </footer>

    </div>
</template>

<script>
 
//import bulmaCarousel from 'bulma-carousel/dist/js/bulma-carousel.min.js';

//bulmaCarousel.attach('#carousel-demo', {
//			slidesToScroll: 1,
//			slidesToShow: 4
//});
    
export default {
  name: 'Home',
  props: {
    msg: String
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#divcentered {
  width: 50%;
  margin: 0 auto;
}

.tile {
  display: flex;
  align-items: center;
}

.va {
  display: flex;
  align-items: center;
}

.hero,
.cta {
   color: white;
}

.cta {
  height: 500px;
  border-top: 10px solid turquoise;
}

footer {
  color: white;
  background: white !important;
}

footer strong {
  color: #B38F06;;
}

footer a {
  color: #B38F06;;
}

.title {
  color: #B38F06;
  font-weight: 400;
  font-size: 1.5rem;
  font-family: 'Nunito Sans', sans-serif;

}

.title2 {
  color: #B38F06;
  font-weight: 400;
  font-size: 1rem;
  font-family: 'Nunito Sans', sans-serif;

}

.subtitle {
  color: #cc3366;
   font-weight: 400;
  font-size: 1rem;
  font-family: 'Nunito Sans', sans-serif;
}

hr.content-divider {
  max-width: 75px;
  height: 5px;
}

#bg-1 {
  background: url("../assets/img/funerals/art-beach-beautiful-269583.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 1px solid #B38F06;;
}

#bg-1 hr {
  background: #B38F06;;
}

#bg-2 {
  background: url("../assets/img/weddings/bloom-blooming-blossom-677863.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 1px solid #B38F06;;
}

#bg-2 hr {
  background: #B38F06;
}

#bg-3 {
  background: url("../assets/img/babynamings/baby-beach-child-51953.jpg");   
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 1px solid #B38F06;;
}

#bg-3 hr {
  background: #B38F06;;
}

#bg-4 {
  background: url("../assets/img/weddings/beautiful-bloom-blooming-916340.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 1px solid #B38F06;;
}

#bg-4 hr {
  background: #B38F06;;
}


.label {
  color: white;
}

input {
  background: transparent !important;
  border: 1px solid turquoise !important;
  color: white !important;
}

input::placeholder {
  color: white !important;
}

</style>
